<template>
  <div class="move-shot">
    <div class="title">
      <p class="title-p">拍摄视频</p>
      <p class="close">
        <i @click="closemodel" class="el-icon-close close-i"></i>
      </p>
    </div>
    <div class="body">
      <p class="stepdetail">
        <span style="color: rgb(92 118 232); font-weight: 600">1.</span
        >在小红书或皮皮虾等app复制视频链接，电脑微信小程序“橘子去水印”，保存视频文件
      </p>
      <p class="stepdetail">
        <span style="color: rgb(92 118 232); font-weight: 600">2.</span
        >点击下方“上传视频”->开始推流 -> 打开云手机内App拍摄
      </p>
      <p class="stepdetail">
        <span style="color: rgb(92 118 232); font-weight: 600">3.</span
        >关闭视频原音，添加App自带音乐，添加#hashtag, 更改封面后点击post上传
      </p>
      <p class="step-content">
        注：开始推流前请不要提前开启App拍摄,目前视频分辨率支持720*1280或1080*1920
      </p>
      <el-button
        @click="uploadfile"
        size="mini"
        type="primary"
        class="uploadbtn"
      >
        上传视频
      </el-button>
      <el-button size="mini" type="primary" class="refreshbtn" @click="stoprtmp"
        >停止推流</el-button
      >
      <el-button
        @click="renewlist"
        size="mini"
        type="primary"
        class="refreshbtn"
      >
        刷新
      </el-button>
      <el-button
        class="searchbtn"
        size="mini"
        type="primary"
        @click="handleSearch"
      >
        搜索
      </el-button>
      <el-input
        class="search-input"
        v-model="searchvalue"
        @focus="serchfocus"
        @blur="serchblur"
        size="mini"
        placeholder="请输入搜索文本"
        style="width: 220px"
        @keyup.enter.native="handleSearch"
        clearable
      />
      <div class="screen" v-if="isSearch">
        <div class="screen-title">
          共{{ this.tabledata.length }}条数据<el-divider
            direction="vertical"
          ></el-divider
          >筛选条件：{{ this.searchvalue }}
        </div>
        <el-button size="mini" type="primary" class="clear" @click="renewlist"
          >清空</el-button
        >
      </div>
      <com-table
        v-loading="loading"
        class="filestoragelist"
        :headercellstyle="headercellstyle"
        :pagination="pagination"
        :columns="columns"
        :data="tabledata"
        tableheight="445"
        tablesize="small"
      >
        <el-table-column
          align="center"
          header-align="center"
          slot="action"
          label="操作"
          min-width="150"
        >
          <template slot-scope="scope">
            <el-button
              @click="startlive(scope.row)"
              :disabled="scope.row.islive"
              size="mini"
              type="primary"
              :class="['startbtn', { livingbtn: scope.row.islive == true }]"
              >{{ scope.row.islive ? "正在推流" : "开始推流" }}</el-button
            >
            <el-button
              @click="deletefile(scope.row)"
              :disabled="scope.row.islive"
              size="mini"
              type="primary"
              class="phonebtn"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </com-table>
    </div>
  </div>
</template>
<script>
import ComTable from "../../../../components/Table";
import formatdate from "../../../../utils/format";
import UpLoadModel from "../../../StorageFiles/Models/UpLoadModel.vue";
export default {
  components: { ComTable, UpLoadModel },
  data() {
    return {
      region: "hk",
      loading: false,
      headercellstyle: {
        background: "rgba(228, 229, 241)",
        color: "rgba(102,102,102)",
      },
      tabledata: [],
      columns: [
        {
          prop: "FileName",
          label: "文件名",
          width: "100",
          align: "center",
          headerAlign: "center",
          selection: false,
          index: false,
          expand: false,
        },
        {
          prop: "UploadTime",
          label: "上传时间",
          width: "100",
          align: "center",
          headerAlign: "center",
          selection: false,
          index: false,
          expand: false,
          formatter: (row, column, cellValue, index) => {
            return formatdate(cellValue, "YYYY-MM-DD HH:MM");
          },
        },
      ],
      pagination: {
        current: 1,
        size: 20,
        total: 1,
      },
      searchvalue: "",
      isSearch: false,
      showuploadmodel: false,
    };
  },
  methods: {
    closemodel() {
      this.$emit("closeshowtransform");
    },
    getufilelist() {
      this.loading = true;
      let payload = {
        StorageRegion: this.region,
        FileType: "FILE_LIVE",
      };
      this.$store
        .dispatch("storage/getufilelist", { ...payload })
        .then((res) => {
          if (res.RetCode == 0) {
            this.loading = false;
            this.tabledata = res.Files;
          } else {
            this.loading = false;
          }
        });
    },
    serchfocus() {
      this.$emit("removelistener");
      console.log("focus");
    },
    serchblur() {
      this.$emit("addlistener");
      console.log("blur");
    },
    renewlist() {
      this.isSearch = false;
      this.getufilelist();
    },
    handleSearch() {
      var list = this.tabledata.filter((item, index) => {
        if (item.FileName.includes(this.searchvalue)) {
          return true;
        }
      });
      this.tabledata = list;
      this.isSearch = true;
    },
    uploadfile() {
      this.$emit("uploadfile");
    },
    getfilesurl(row) {
      let payload = {
        FileId: row.FileId,
        Expires: 48 * 3600,
      };
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("storage/getstorageurl", { ...payload })
          .then((res) => {
            if (res.RetCode == 0) {
              resolve(res.SignedURL);
            }
          });
      });
    },
    startlive(row) {
      var data = JSON.parse(JSON.stringify(this.tabledata));
      var tabledata = data.filter((item, index) => {
        item.islive = false;
        if (item.FileId == row.FileId) {
          item.islive = true;
          this.islive = true;
        }
        return true;
      });
      this.tabledata = tabledata;
      this.getfilesurl(row).then((res) => {
        this.$emit("startunmannedlive", res);
      });
    },
    stoprtmp() {
      var data = JSON.parse(JSON.stringify(this.tabledata));
      var tabledata = data.filter((item, index) => {
        item.islive = false;
        return true;
      });
      this.tabledata = tabledata;
      this.$emit("stopunmannedlive");
    },
    deletefile(row) {
      this.$emit("deletefile", row);
    },
  },
  mounted() {
    this.getufilelist();
    console.log("mounted");
  },
  beforeDestroy() {
    console.log("beforedestroy");
  },
};
</script>
<style lang="scss" scoped>
.move-shot {
  width: 600px;
  height: 746px;
  background: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  .title {
    height: 36px;
    border-bottom: 1px solid #eee;
    display: flex;
    .title-p {
      font-size: 14px;
      flex: 1;
      line-height: 36px;
      box-sizing: border-box;
      padding-left: 10px;
      color: #000;
    }
    .close {
      width: 25px;
      height: 100%;
      line-height: 36px;
      font-size: 16px;
      .close-i {
        cursor: pointer;
      }
    }
  }
  .body {
    width: 100%;
    height: calc(100% - 36px);
    box-sizing: border-box;
    padding: 10px;
    overflow-y: auto;
    .screen {
      background-color: rgb(251, 250, 252);
      border: 1px solid rgb(212, 214, 232);
      height: 50px;
      margin: auto;
      margin-top: 20px;

      .screen-title {
        font-size: 13px;
        margin-left: 20px;
        margin-top: 15px;
        display: inline-block;
      }

      .clear {
        float: right;
        margin-right: 20px;
        margin-top: 10px;
      }
    }
    .stepdetail {
      line-height: 26px;
      color: #000;
    }
    .step-content {
      font-size: 13px;
      margin-bottom: 5px;
      color: rgb(228, 79, 79);
    }
    .startbtn,
    .stopbtn,
    .phonebtn,
    .uploadbtn,
    .refreshbtn,
    .searchbtn {
      color: #fff;
      margin-left: 8px;
      border-radius: 5px;
      box-shadow: rgb(92 118 232) 0px 2px 4px -1px,
        rgb(0 0 0 / 12%) 0px -3px 0px 0px inset,
        rgb(0 0 0 / 12%) 0px 1px 0px 0px inset;
      background: linear-gradient(
        135deg,
        rgb(100, 136, 252) 0%,
        rgb(56, 96, 244) 100%
      );
    }
    .livingbtn {
      color: #fff;
      margin-left: 8px;
      border-radius: 5px;
      box-shadow: rgb(92 118 232) 0px 2px 4px -1px,
        rgb(0 0 0 / 12%) 0px -3px 0px 0px inset,
        rgb(0 0 0 / 12%) 0px 1px 0px 0px inset;
      background: linear-gradient(
        90deg,
        rgba(74, 99, 240, 0.93) 0%,
        rgba(236, 179, 242, 1) 100%
      );
    }
    .refreshbtn {
      margin-top: 10px;
    }
    .searchbtn,
    .search-input {
      float: right;
      margin-top: 10px;
    }
    .filestoragelist {
      margin-top: 20px;
    }
  }
}
</style>