var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    { groupbottom4: _vm.pagesize == 4 },
    { groupbottom6: _vm.pagesize == 6 },
    { groupbottom8: _vm.pagesize == 8 },
    { groupbottom12: _vm.pagesize == 12 },
    { groupbottom24: _vm.pagesize == 24 },
    { groupbottom30: _vm.pagesize == 30 },
    { groupbottom48: _vm.pagesize == 48 },
    { groupbottom50: _vm.pagesize == 50 },
  ],attrs:{"id":"player"}},_vm._l((_vm.showphonelist),function(item,index){return _c('div',{key:item.phoneId,class:[
      { vcontain4: _vm.pagesize == 4 },
      { vcontain6: _vm.pagesize == 6 },
      { vcontain8: _vm.pagesize == 8 },
      { vcontain12: _vm.pagesize == 12 },
      { vcontain24: _vm.pagesize == 24 },
      { vcontain30: _vm.pagesize == 30 },
      { vcontain48: _vm.pagesize == 48 },
      { vcontain50: _vm.pagesize == 50 },
    ]},[_c('div',{staticClass:"videoitem",on:{"click":function($event){return _vm.showmodelcontrol(item, index)}}},[_c('control-item',{ref:"videoitem",refInFor:true,attrs:{"id":item.phoneId,"pagesize":_vm.pagesize}})],1),_c('p',{staticStyle:{"font-size":"12px","color":"#000","text-align":"center","white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"},attrs:{"title":item.phoneId}},[(_vm.full)?_c('span',[_vm._v(_vm._s(item.Id.substring(item.Id.indexOf("-") + 1)))]):_c('el-checkbox',{on:{"change":(val) => {
            _vm.ifitemchecked(val, item.value, item);
          }},model:{value:(item.itemchecked),callback:function ($$v) {_vm.$set(item, "itemchecked", $$v)},expression:"item.itemchecked"}},[_vm._v(_vm._s(item.Id.substring(item.Id.indexOf("-") + 1)))])],1)])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }