<template>
  <div
    id="player"
    :class="[
      { groupbottom4: pagesize == 4 },
      { groupbottom6: pagesize == 6 },
      { groupbottom8: pagesize == 8 },
      { groupbottom12: pagesize == 12 },
      { groupbottom24: pagesize == 24 },
      { groupbottom30: pagesize == 30 },
      { groupbottom48: pagesize == 48 },
      { groupbottom50: pagesize == 50 },
    ]"
  >
    <div
      v-for="(item, index) in showphonelist"
      :key="item.phoneId"
      :class="[
        { vcontain4: pagesize == 4 },
        { vcontain6: pagesize == 6 },
        { vcontain8: pagesize == 8 },
        { vcontain12: pagesize == 12 },
        { vcontain24: pagesize == 24 },
        { vcontain30: pagesize == 30 },
        { vcontain48: pagesize == 48 },
        { vcontain50: pagesize == 50 },
      ]"
    >
      <div class="videoitem" @click="showmodelcontrol(item, index)">
        <control-item
          ref="videoitem"
          :id="item.phoneId"
          :pagesize="pagesize"
        ></control-item>
      </div>
      <p
        style="
          font-size: 12px;
          color: #000;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        "
        :title="item.phoneId"
      >
        <span v-if="full">{{ item.Id.substring(item.Id.indexOf("-") + 1)}}</span>
        <el-checkbox
          v-else
          v-model="item.itemchecked"
          @change="
            (val) => {
              ifitemchecked(val, item.value, item);
            }
          "
          >{{ item.Id.substring(item.Id.indexOf("-") + 1)}}</el-checkbox
        >
      </p>
    </div>
  </div>
</template>
<script>
import ControlItem from "../ControlItem/index.vue";
import { fullScreen } from "../../../utils/fullscreen.js";
export default {
  props: {
    showphonelist: {
      type: Array,
      default: () => [],
    },
    pagesize: {
      type: Number,
      default: 4,
    },
  },
  data() {
    return {
      full: false,
    };
  },
  components: {
    ControlItem,
  },
  methods: {
    fullplayer() {
      fullScreen(document.getElementById("player"));
    },
    checkfull() {
      var isfull =
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement;
      if (isfull == null) isfull = false;
      return isfull;
    },
    resize(e) {
      if (!this.checkfull()) {
        this.full = false;
      } else {
        this.full = true;
      }
      // console.log(this.full)
    },
    showmodelcontrol(item, index) {
      if (this.full) {
        return;
      }
      //console.log("showmodelcontrol: " + JSON.stringify(item));
      this.$emit("showmodelcontrol", item, index);
    },
    ifitemchecked(val, value, item) {
      //console.log("ifitemchecked val: " + val + " value: " + value);
      this.$emit("ifitemchecked", val, value, item);
    },
  },
  mounted() {
    window.addEventListener("resize", this.resize);
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>