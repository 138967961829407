<template>
  <models
    :showmodel="showsingleuploadmodel"
    :title="title"
    :canceltext="canceltext"
    :confirmtext="confirmtext"
    :needfooter="needfooter"
    :phoneid="phoneid"
    :phonename="phonename"
    @confirm="confirmupload"
    @closemodel="closeshowsingleupload"
    @open="open"
    class="singleuploadmodel"
  >
    <div class="warningtitle">
      <p class="warningcontent">
        上传后的文件存放在云手机:桌面/文件/下载，如上传文件为apk则自动安装
      </p>
    </div>
    <com-table
      class="renewlist"
      :headercellstyle="headercellstyle"
      :columns="renewList"
      :data="tabledata"
      tableheight="200"
    >
    </com-table>
    <el-upload
      ref="upload"
      :show-file-list="false"
      action="bucketUrl"
      :auto-upload="false"
      :file-list="fileList"
      :on-change="onchangefile"
      :multiple="true"
    >
    </el-upload>
    <el-button size="mini" type="primary" @click="addfile" class="uploadbtn"
      >添加文件</el-button
    >
    <com-table
      class="filecomtable"
      :data="showfileList"
      :columns="filecloumns"
      tableheight="300"
      :pagination="pagination"
      :headercellstyle="headercellstyle"
    >
      <el-table-column
        align="center"
        header-align="center"
        slot="action"
        fixed="right"
        label="操作"
        min-width="100"
      >
        <template slot-scope="scope">
          <el-button
            @click="handleClick(scope.row)"
            type="primary"
            size="mini"
            class="deletbtn"
            >移除</el-button
          >
        </template>
      </el-table-column>
    </com-table>
  </models>
</template>
<script>
import Models from "../../../components/Models/index.vue";
import Tree from "../../../components/Tree";
import ComTable from "../../../components/Table";
import { filecloumns } from "../../../utils/tableList";
import { renewList } from "@/utils/tableList";
import Us3Upload from "../../../utils/us3upload";
export default {
  props: {
    showsingleuploadmodel: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    canceltext: {
      type: String,
    },
    confirmtext: {
      type: String,
    },
    needfooter: {
      type: Boolean,
      default: true,
    },
    phoneid: {
      type: String,
    },
    phonename: {
      type: String,
    },
    treedata: {
      type: Array,
      default: () => [],
    },
    row: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      pagination: {
        current: 1,
        size: 20,
        total: 1,
      },
      props: {
        label: function (data, node) {
          if (node.isLeaf) {
            let index = data.label.indexOf("-");
            let filtlable = data.label.substring(index + 1);
            let label = `${data.uphoneName}(${filtlable})`;
            return label;
          } else {
            return data.label;
          }
        },
        children: "children",
      },
      data: [],
      headercellstyle: {
        background: "rgba(228, 229, 241)",
        color: "rgba(102,102,102)",
      },
      filecloumns,
      showfileList: [],
      fileList: [],
      phonelist: [],
      form: {
        PhoneIds: [],
        FileName: "",
        URL: "",
      },
      Us3Upload: null,
      less512List: [],
      more512List: [],
      bucketUrl: "https://wumitech-file-upload-hk.hk.ufileos.com",
      renewList,
      tabledata: [],
    };
  },
  components: {
    Models,
    Tree,
    ComTable,
  },
  watch: {
    treedata: function (val) {
      this.data = val;
    },
    row: function (val) {
      if (Object.entries(val).length == 0) {
        return;
      }
      this.row = val;
    },
  },

  methods: {
    open() {
      this.data = this.treedata;
      this.row.map((item, index) => {
        if (item.status == "RUNNING") {
          this.tabledata.push(item);
        }
      });
      this.showfileList = this.showfileList.filter((item) => {
        if (item.fileprogress == "100%") {
          return false;
        } else {
          return true;
        }
      });
      console.log("open");
    },
    confirmupload() {},
    closeshowsingleupload() {
      this.data = [];
      this.form.PhoneIds = [];
      this.$emit("closeshowsingleupload");
    },
    treecheck() {
      var checkedlist = this.$refs.treefather.$refs.tree.getCheckedNodes(
        false,
        true
      );
      var phoneList = [];
      checkedlist.map((item, index) => {
        if (item.isLeaf == true) {
          phoneList.push(item);
        }
      });
      this.phonelist = phoneList;
      this.form.PhoneIds = phoneList.map((item, index) => {
        return item.value;
      });
    },
    containSpecial(str) {
      var spec = /[',<>{}~!@#$%^&*]/;
      return spec.test(str);
    },
    onchangefile(file, fileList) {
      if (this.containSpecial(file.name)) {
        this.$message.warning("文件名含有特殊字符，请修改后重新选择上传.");
        return;
      }
      let size = file.size;
      if (size < 1024) {
        size = `${file.size}B`;
      }
      if (size / 1024 < 1024) {
        size = `${Math.round((size / 1024) * 100) / 100}KB`;
      }
      if (size / 1024 / 1024 < 1024) {
        size = `${Math.round((size / 1024 / 1024) * 100) / 100}MB`;
      }
      if (size / 1024 / 1024 / 1024 < 1024) {
        size = `${Math.round((size / 1024 / 1024 / 1024) * 100) / 100}GB`;
      }
      if (size / 1024 / 1024 / 1024 / 1024 < 1024) {
        size = `${
          Math.round((size / 1024 / 1024 / 1024 / 1024) * 100) / 100
        }TB`;
      }
      let filedata = {
        uid: file.uid,
        file: file.raw,
        filename: file.name,
        filesize: size,
        filetype: file.raw.type ? file.raw.type : "未知",
        filestate: "上传中",
        fileprogress: 0,
      };
      if (this.showfileList.length) {
        try {
          this.showfileList.forEach((item, index) => {
            if (item.filename == filedata.filename) {
              throw new error();
            }
          });
          this.showfileList.push(filedata);
          this.fileList.push(filedata.file);
          this.submit();
        } catch (error) {}
      } else {
        this.showfileList.push(filedata);
        this.fileList.push(filedata.file);
        this.submit();
      }
    },
    submit() {
      if (!this.Us3Upload) {
        this.Us3Upload = new Us3Upload(
          "wumitech-file-upload-hk",
          this.bucketUrl,
          "hk"
        );
      }
      this.fileList.forEach((item, index) => {
        if (item.size < 512 * 1024 * 1024) {
          if (this.less512List.length) {
            this.less512List.forEach((eitem, eindex) => {
              if (eitem.filename !== item.name) {
                this.less512List = [item];
              }
            });
          } else {
            this.less512List = [item];
          }
        } else {
          if (this.more512List.length) {
            this.more512List.forEach((mitem, mindex) => {
              if (mitem.filename !== item.name) {
                this.more512List = [item];
              }
            });
          } else {
            this.more512List = [item];
          }
        }
      });
      if (this.less512List.length) {
        console.log(this.less512List);
        this.commonUpload();
      }
      if (this.more512List.length) {
        this.multipartUpload();
      }
    },
    commonUpload() {
      var that = this;
      var successCallBack = function (res) {
        console.log(JSON.stringify(res));
        that.downloadToPhone(res);
      };
      var errorCallBack = function (res) {
        console.error("errorCallBack", res);
      };
      var progressCallBack = function (res) {
        var percent = parseFloat(res.progress * 100).toFixed(1);
        var percentComplete = percent + "%";
        // console.log(percentComplete);
        that.showfileList.forEach((item) => {
          if (item.uid == res.fileUid) {
            if (percentComplete == "100.0%") {
              item.fileprogress = percentComplete;
              item.filestate = "正在同步至云手机";
            } else {
              item.fileprogress = percentComplete;
              item.filestate = "上传中";
            }
          }
        });
      };
      this.Us3Upload.batchUpload(
        this.less512List,
        successCallBack,
        errorCallBack,
        progressCallBack
      );
    },
    multipartUpload() {
      var successList = [];
      var currentIndex = 0;
      var that = this;
      var successCallBack = function (res) {
        successList.push(res.file);
        if (that.more512List.length !== successList.length) {
          currentIndex++;
          that.Us3Upload.sliceUpload(
            {
              file: that.more512List[currentIndex],
            },
            successCallBack,
            errorCallBack,
            progressCallBack
          );
        }
        console.log(res);
        that.downloadToPhone(res);
      };
      var errorCallBack = function (res) {
        console.error("multipartUpload errorCallBack: ", res);
      };
      var progressCallBack = function (res) {
        var percent = parseFloat(res.value * 100).toFixed(1);
        var percentComplete = percent + "%";
        // console.log(percentComplete);
        that.showfileList.forEach((item) => {
          if (item.uid == res.fileUid) {
            if (percentComplete == "100.0%") {
              item.fileprogress = percentComplete;
              item.filestate = "正在同步至云手机";
            } else {
              item.filestate = "上传中";
              item.fileprogress = percentComplete;
            }
          }
        });
      };
      this.Us3Upload.sliceUpload(
        {
          file: this.more512List[currentIndex],
        },
        successCallBack,
        errorCallBack,
        progressCallBack
      );
    },
    downloadToPhone(data) {
      var that = this;
      if (this.Us3Upload == null) {
        return;
      }
      var request = {
        fileName: data.fileName,
        fileUid: data.fileUid,
        fileType: data.fileType,
      };
      var successCallBack = function (res) {
        that.form.FileName = request.fileName;
        that.form.URL = res;
        that.form.uid = request.fileUid;
        console.log(res);
        that.uploadfiles(request);
      };
      var errorCallBack = function (res) {
        console.error("errorCallBack", res);
      };
      that.Us3Upload.getDownloadUrl(request, successCallBack, errorCallBack);
    },
    uploadfiles(request) {
      let data = {
        ...this.form,
      };
      var successCallBack = function (res) {
        console.log(res);
      };
      var errorCallBack = function (res) {
        console.log(res);
      };
      this.$store.dispatch("control/importfiles", { ...data }).then((res) => {
        if (res.RetCode == 0) {
          this.showfileList.forEach((item, index) => {
            if (item.uid == data.uid) {
              item.filestate = "上传成功";
              item.fileprogress = "100%";
            }
          });
          // this.Us3Upload.deleteFile(
          //   request.fileName,
          //   request.fileType,
          //   successCallBack,
          //   errorCallBack
          // );
        }
      });
    },
    addfile() {
      this.form.PhoneIds = [];
      this.tabledata.forEach((item) => {
        this.form.PhoneIds.push(item.name);
      });

      this.$refs["upload"].$refs["upload-inner"].handleClick();
    },
    handleClick(row) {
      this.showfileList.forEach((item, index) => {
        if (item.uid == row.uid) {
          this.showfileList.splice(index, 1);
          this.fileList.splice(index, 1);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.singleuploadmodel {
  ::v-deep .el-dialog {
    width: 900px;
    height: 800px;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog__footer {
    padding: 0;
  }
  ::v-deep .el-dialog__body {
    height: 500px;
  }
  .uploadbtn {
    border-radius: 5px;
    width: 100px;
    background: rgba(88, 151, 242, 1);

    &:hover {
      background: rgba(88, 151, 242, 1);
    }
  }
  .warningtitle {
    height: 40px;
    margin-bottom: 20px;
    background-color: rgba(228, 229, 241);
    line-height: 40px;
    color: rgb(102, 102, 102);
    font-size: 14px;
    .warningcontent {
      margin-left: 20px;
    }
  }
  .filecomtable {
    margin-top: 20px;
  }
}
</style>