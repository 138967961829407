<template>
  <div class="bottom-page">
    <!-- <p class="selectcount">
      已选择 <span>{{ checkednum }}</span> 台云机
    </p> -->
    <pagination
      :currentPage="currentPage"
      :pagesize="pagesize"
      :total="total"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    />
    <!-- <p @click="showprogress" class="progresscount">任务进度({{jobprogress}}%)</p> -->
  </div>
</template>
<script>
import Pagination from "../../../components/Pagination/index.vue";
export default {
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    pagesize: {
      type: Number,
      default: 4,
    },
    total: {
      type: Number,
      default: 0,
    },
    checkednum: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      //checkednum: 0,
    };
  },
  components: {
    Pagination,
  },
  methods: {
    handleSizeChange(val) {
      this.$emit("handleSizeChange", val);
    },
    handleCurrentChange(current) {
      this.$emit("handleCurrentChange", current);
    },
  },
};
</script>
<style lang="scss" scoped>
.bottom-page {
  width: 100%;
  height: 50px;
  position: relative;
}
.selectcount {
  position: absolute;
  font-size: 14px;
  top: 16px;
  left: 20px;
  span {
    color: rgb(56, 96, 244);
  }
}
::v-deep .el-pagination {
  position: absolute;
  top: 8px;
  right: 10px;
}
</style>