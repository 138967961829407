<template>
  <models
    :showmodel="showresolumodel"
    :title="title"
    :canceltext="canceltext"
    :confirmtext="confirmtext"
    :needcancel="needcancel"
    @confirm="confirmresolute"
    @closemodel="closeshowresolute"
    class="resolutemodel"
  >
    <div class="warningtitle">
      <p class="warningcontent">设置分辨率&Dpi将重启云手机</p>
    </div>
    <el-form
      class="content-form"
      abel-position="right"
      label-width="86px"
      :model="formvalue"
      ref="setresolute"
    >
      <el-form-item label="设置分辨率:" prop="resolutionvalue">
        <el-select
          v-model="formvalue.resolutionvalue"
          placeholder="请选择分辨率"
          @change="selectchange"
        >
          <el-option
            v-for="item in resolutoptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Dpi:" prop="dpinum">
        <el-slider
          :disabled="true"
          v-model="formvalue.dpinum"
          :min="160"
          :max="480"
          show-input
        >
        </el-slider>
      </el-form-item>
    </el-form>
  </models>
</template>
<script>
import Models from "../../../components/Models/index.vue";
export default {
  props: {
    query: {
      type: Object,
    },
    checkeditem: {
      type: Array,
    },
    showresolumodel: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    canceltext: {
      type: String,
    },
    confirmtext: {
      type: String,
    },
    needcancel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      formvalue: {
        resolutionvalue: "1",
        dpinum: 320,
      },
      resolutoptions: [
        {
          value: "1",
          label: "720x1280",
        },
        {
          value: "2",
          label: "1080x1920",
        },
      ],
      alreadycheckeditem: [],
    };
  },
  components: { Models },
  watch: {
    checkeditem(val) {
      this.alreadycheckeditem = val;
    },
  },
  methods: {
    confirmresolute() {
      let data = {
        PhoneIds: [],
        Resolution: "",
        Dpi: this.formvalue.dpinum,
      };
      this.alreadycheckeditem.forEach((item, index) => {
        data.PhoneIds.push(item);
      });
      if (this.formvalue.resolutionvalue == 1) {
        data.Resolution = "720x1280";
      }
      if (this.formvalue.resolutionvalue == 2) {
        data.Resolution = "1080x1920";
      }
      this.$store
        .dispatch("control/phonesetconfig", { ...data })
        .then((res) => {
          if (res.RetCode == 0) {
            this.$message.success("正在设置分辨率&DPI")
            // this.$emit("getphonetask", { PhoneIds: data.UPhoneIds });
            this.closeshowresolute();
          } else {
            this.$message.error("设置失败,请重试");
          }
        });
    },
    closeshowresolute() {
      this.$refs["setresolute"].resetFields();
      this.$emit("closeshowresolute");
    },
    selectchange(value) {
      if (value == 1) {
        this.formvalue.dpinum = 320;
      }
      if (value == 2) {
        this.formvalue.dpinum = 480;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.resolutemodel {
  ::v-deep .el-dialog {
    width: 480px;
    height: 356px;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog__footer {
    padding: 0;
  }
  .warningtitle {
    height: 40px;
    margin-bottom: 20px;
    background-color: rgba(228, 229, 241);
    line-height: 40px;
    color: rgb(102, 102, 102);
    font-size: 14px;
    .warningcontent {
      margin-left: 20px;
    }
  }
}
</style>