<template>
  <div
    class="videoloading"
    v-loading="loading"
    :element-loading-text="loadingtext"
    element-loading-spinner="el-icon-loading"
    element-loading-background="transparent"
  >
    <div v-if="isduplicate" class="result">
      <i v-if="pagesize < 24" :class="['el-icon-warning', 'resulticon']"></i>
      <p v-if="pagesize < 24" class="resultitle">连接断开</p>
      <p :class="['resultcontent', { resultchange: pagesize >= 24 }]">
        此云手机正在其他地方连接
      </p>
    </div>
    <!-- <el-result
      v-if="isduplicate"
      icon="warning"
      :title="duplicatetext"
      subTitle="此云手机正在其他地方连接"
    >
    </el-result> -->
    <video
      id="video"
      ref="revideo"
      :poster="poster"
      muted
      autoplay
      playsinline
      webkit-playsinline
    ></video>
  </div>
</template>
 
<script>
import { getstream, getrtc } from "../../../utils/groupconnection";
export default {
  props: {
    id: String,
    show: Boolean,
    pagesize: Number,
  },
  data() {
    return {
      loading: true,
      loadingtext: "加载中",
      icestatus: "disconnected",
      remotestream: "",
      poster: require("../../../assets/back.jpg"),
      isresult: false,
      isduplicate: false,
      duplicatetext: "连接断开",
      remotestreamtimmer: null,
    };
  },
  computed: {
    getrtclist() {
      return this.$store.getters.rtclist;
    },
  },
  watch: {
    remotestream(newVal, oldVal) {
      console.log(newVal);
      console.log(this.id + "streamnew");
      this.$refs.revideo.srcObject = newVal;
      var this_ = this;
      this.$refs.revideo.onloadedmetadata = function onloadedmetadata() {
        console.log("第一帧" + this_.id);
        this_.loading = false;
        console.log(this.videoHeight, this.videoWidth);
      };
    },
    getrtclist: {
      handler(newVal, oldVal) {
        // console.log("controlitem getrtclist new: " + JSON.stringify(newVal));
        //console.log("controlitem getrtclist old: " + JSON.stringify(oldVal));
        const data = JSON.parse(JSON.stringify(newVal));
        if (data.length == 0) {
          this.icestatus = "disconnected";
          this.loading = false;
          this.isduplicate = false;
        }
        try {
          data.forEach((item, index) => {
            if (item.phoneId == this.id) {
              this.loadingtext = item.icestatustext;
              if (item.icestatus == 136) {
                this.isduplicate = true;
                this.loading = false;
                this.icestatus = "disconnected";
                throw new error();
              }
              if (item.icestatus == 500) {
                // this.remotestream = getstream(this.id);
                throw new error();
              }
              // if (item.icestatus == 10) {
              //   this.icestatus = "disconnected";
              //   this.loading = true;
              //   console.log(item.icestatustext);
              // }
              if (item.icestatus == "connected") {
                this.isduplicate = false;
                this.remotestream = getstream(this.id);
                this.icestatus = "connected";
                this.loading = false;
              } else {
                this.remotestream = getstream(this.id);
                this.icestatus = "disconnected";
                this.loading = true;
              }
            } else {
            }
          });
        } catch (error) {
          return;
        }
      },
      deep: true,
      immediate: false,
    },
  },
  methods: {
    forceconnect() {
      var rtc = getrtc(this.id);
      rtc.forceconnent();
      console.log("force");
    },
    clearstatus() {
      this.isduplicate = false;
      this.loading = false;
      this.icestatus = "disconnected";
    },
    // checkresult() {
    //   if (!this.isresult) {
    //     this.isresult = true;
    //   }
    // },
  },
  created() {},
  mounted() {
    var data = this.$store.getters.rtclist;
    data.forEach((item, index) => {
      if (item.phoneId == this.id) {
        if (item.isresult) {
          this.isresult = true;
          this.loading = false;
          this.icestatus = "disconnected";
          this.loadingtext = item.icestatustext;
          console.log(item.icestatus);
        }
        if (item.icestatus == 136) {
          this.isduplicate = true;
          this.loading = false;
          this.icestatus = "disconnected";
        }
      }
    });
    this.remotestream = getstream(this.id);
    this.remotestreamtimmer = setInterval(() => {
      this.remotestream = getstream(this.id);
    }, 3000);
    console.log(this.id + "挂载");
  },
  beforeDestroy() {
    this.remotestream = "";
    clearInterval(this.remotestreamtimmer);
    console.log(this.id + "销毁");
  },
};
</script>

<style scoped lang="scss">
.videoloading {
  width: 100%;
  height: 100%;
  position: relative;
  // background: #000;
  .result {
    z-index: 100;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-size: 12px;
    // align-items: center;
    .resulticon {
      text-align: center;
      font-size: 3rem;
      color: #e6a23c;
      margin-top: 30px;
      margin-bottom: 20px;
    }
    .resulticonchange {
      margin-top: 1rem;
      margin-bottom: 1rem;
      text-align: center;
      font-size: 1rem;
      color: #e6a23c;
    }
    .resultitle {
      height: 20%;
      text-align: center;
      color: rgb(145, 141, 141);
      font-size: 1rem;
    }
    .resultcontent {
      height: 20%;
      text-align: center;
      color: rgb(145, 141, 141);
      font-size: 1rem;
    }
    .resultchange {
      height: 100%;
      text-align: center;
      margin: auto;
      color: rgb(145, 141, 141);
      font-size: 0.5rem;
    }
  }
  .el-result {
    z-index: 100;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .el-button-style {
    font-size: 12px;
    color: #fff;
    box-shadow: rgb(92 118 232) 0px 2px 4px -1px,
      rgb(0 0 0 / 12%) 0px -3px 0px 0px inset,
      rgb(0 0 0 / 12%) 0px 1px 0px 0px inset;
    background: linear-gradient(
      135deg,
      rgb(100, 136, 252) 0%,
      rgb(56, 96, 244) 100%
    );
  }
  .el-result__title p {
    //color: #768de2;
    color: #fff;
  }
}
#video {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.status {
  font-size: 25px;
  color: #67c23a;
  position: absolute;
  top: -15px;
  right: -15px;
}
.warning-status {
  font-size: 25px;
  color: #e6a23c;
  position: absolute;
  top: -15px;
  right: -15px;
}
</style>