<template>
  <models
    :showmodel="showsetip"
    :title="title"
    :canceltext="canceltext"
    :confirmtext="confirmtext"
    :needcancel="needcancel"
    @confirm="confirmipregion"
    @closemodel="closeshowipregion"
    class="setipmodel"
    @open="open"
  >
    <div class="warningtitle">
      <p class="warningcontent">只针对无网版云手机配置海外IP</p>
    </div>
    <div class="table-bar">
      <!-- <el-button @click="handleAddRow" size="mini" type="primary"
        >新增</el-button
      >
      <el-button @click="handleDeleteRow" size="mini" type="primary"
        >删除</el-button
      > -->
      <el-button size="mini" type="primary" @click="handleDownload"
        >导出Excel模板</el-button
      >
      <upload-excel :on-success="handleSuccess" :before-upload="beforeUpload" />
      <span @click="showhistroymodel" class="table-right">历史配置</span>
    </div>
    <el-form :model="model" ref="socksform">
      <com-table
        ref="comtable"
        :headercellstyle="headercellstyle"
        tablesize="small"
        tableheight="400"
        style="width: 100%"
        :pagination="pagination"
        :columns="ipcolumns"
        :data="model.ipregionlist"
        @rowClassName="rowClassName"
        @handleSelectionChange="handleSelectionChange"
      >
        <el-table-column
          slot="action"
          align="left"
          label="IP地址/域名"
          width="200"
          prop="IP"
        >
          <template slot-scope="scope">
            <el-form-item
              :prop="`ipregionlist[${scope.$index}].IP`"
              :rules="[
                { required: true, message: 'IP地址/域名不能为空', trigger: 'blur' },
                {
                  pattern: /^[^\u4e00-\u9fa5]+$/,
                  message: '不能存在中文',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                :clearable="true"
                size="mini"
                v-model.trim="scope.row.IP"
                placeholder="请输入IP或域名"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          slot="action"
          align="left"
          label="IP端口"
          width="150"
          prop="dk"
        >
          <template slot-scope="scope">
            <el-form-item
              :prop="`ipregionlist[${scope.$index}].dk`"
              :rules="[
                { required: true, message: 'IP端口不能为空', trigger: 'blur' },
                {
                  pattern: /^[^\u4e00-\u9fa5]+$/,
                  message: '不能存在中文',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                :clearable="true"
                v-model.trim="scope.row.dk"
                size="mini"
                placeholder="请输入IP端口"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          slot="action"
          align="left"
          label="IP账号"
          width="200"
          prop="account"
        >
          <template slot-scope="scope">
            <el-form-item
              :prop="`ipregionlist[${scope.$index}].account`"
              :rules="[
                { required: true, message: '请输入IP账号', trigger: 'blur' },
              ]"
            >
              <el-input
                :clearable="true"
                v-model.trim="scope.row.account"
                size="mini"
                placeholder="请输入IP账号"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          slot="action"
          align="left"
          label="IP密码"
          width="200"
          prop="password"
        >
          <template slot-scope="scope">
            <el-form-item
              :prop="`ipregionlist[${scope.$index}].password`"
              :rules="[
                { required: true, message: '请输入IP密码', trigger: 'blur' },
              ]"
            >
              <el-input
                :clearable="true"
                type="password"
                :show-password="true"
                size="mini"
                v-model.trim="scope.row.password"
                placeholder="请输入IP密码"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
      </com-table>
    </el-form>
  </models>
</template>
<script>
import Models from "../../../components/Models/index.vue";
import ComTable from "../../../components/Table/index.vue";
import UploadExcel from "../../../components/UploadExcel/index.vue";
export default {
  props: {
    showsetip: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    canceltext: {
      type: String,
    },
    confirmtext: {
      type: String,
    },
    needcancel: {
      type: Boolean,
      default: true,
    },
    checkeditem: {
      type: Array,
    },
  },
  components: { Models, ComTable, UploadExcel },
  watch: {
    checkeditem(val) {
      this.alreadychecked = val;
      // console.log(val)
    },
  },
  data() {
    return {
      ipcolumns: [
        // {
        //   width: "30",
        //   align: "left",
        //   selection: true,
        //   reserve: true,
        // },
        {
          prop: "xh",
          label: "序号",
          width: "60",
          align: "left",
          headerAlign: "left",
          selection: false,
          index: false,
          expand: false,
        },
        {
          prop: "phoneId",
          label: "云手机ID",
          width: "150",
          align: "left",
          headerAlign: "left",
          selection: false,
          index: false,
          expand: false,
        },
        {
          prop: "socks5",
          label: "协议",
          width: "80",
          align: "left",
          headerAlign: "left",
          selection: false,
          index: false,
          expand: false,
        },
      ],
      headercellstyle: {
        background: "rgba(228, 229, 241)",
        color: "rgba(102,102,102)",
      },
      pagination: {
        current: 1,
        size: 20,
        total: 1,
      },
      alreadychecked: [],
      model: {
        ipregionlist: [],
      },
      ipregionlist: [],
      checkedDetail: [],
      filename: "",
      autoWidth: true,
      bookType: "xlsx",
      showhistroyip: false,
    };
  },
  methods: {
    open() {
      this.alreadychecked.forEach((item, index) => {
        let obj = {
          phoneId: item,
          IP: "",
          dk: "",
          account: "",
          password: "",
        };
        this.model.ipregionlist.push(obj);
      });
      console.log(this.model.ipregionlist);
    },
    //导出excel
    handleDownload() {
      import("../../../vendor/Export2Excel.js").then((excel) => {
        const tHeader = [
          "序号",
          "云手机ID",
          "协议",
          "IP地址",
          "端口",
          "账号",
          "密码",
        ];
        const filterVal = [
          "xh",
          "phoneId",
          "socks5",
          "IP",
          "dk",
          "account",
          "password",
        ];
        const list = this.model.ipregionlist;
        const data = this.formatJson(filterVal, list);
        console.log(data);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.filename,
          autoWidth: this.autoWidth,
          bookType: this.bookType,
        });
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          if (j === "timestamp") {
            return parseTime(v[j]);
          } else {
            return v[j];
          }
        })
      );
    },
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (isLt1M) {
        return true;
      }
      this.$message({
        message: "Please do not upload files larger than 1m in size.",
        type: "warning",
      });
      return false;
    },
    //导入成功
    handleSuccess({ results, header }) {
      let list = [];
      results.forEach((item, index) => {
        let obj = {};
        obj["xh"] = item["序号"];
        obj["phoneId"] = item["云手机ID"];
        obj["IP"] = item["IP地址"];
        obj["dk"] = item["端口"];
        obj["account"] = item["账号"];
        obj["password"] = item["密码"];
        list.push(obj);
      });
      this.model.ipregionlist = list;
      console.log(results, header);
    },
    handleSelectionChange(val) {
      this.checkedDetail = val;
      console.log(val);
    },
    rowClassName(row, rowIndex) {
      row.xh = rowIndex + 1;
      row.socks5 = "socks5";
    },
    handleAddRow() {
      let obj = {
        phoneId: "",
        IP: "",
        dk: "",
        account: "",
        password: "",
      };
      this.model.ipregionlist.push(obj);
    },
    handleDeleteRow() {
      if (this.checkedDetail.length == 0) {
        this.$message.warning("请选择要删除的数据");
      } else {
        this.checkedDetail.forEach((item, index) => {
          this.model.ipregionlist.forEach((pitem, pindex) => {
            if (item.xh == pitem.xh) {
              this.model.ipregionlist.splice(pindex, 1);
            }
          });
        });
        this.$refs.comtable.$refs.myTable.clearSelection();
      }
    },
    confirmipregion() {
      this.$refs.socksform.validate((valid) => {
        if (valid) {
          let payload = {
            Configs: [],
          };
          payload.Configs = this.model.ipregionlist.map((item, index) => {
            let obj = {};
            obj.PhoneId = item.phoneId;
            obj.SocksConfig = {
              Password: item.password,
              Port: Number(item.dk),
              Uri: item.IP,
              UserName: item.account,
            };
            return obj;
          });
          this.$store
            .dispatch("control/phonesetsocks", { ...payload })
            .then((res) => {
              if (res.RetCode == 0) {
                this.$message.success("正在配置IP");
                this.closeshowipregion();
              } else {
                this.$message.error("配置IP失败");
              }
            });
          console.log(payload);
          console.log(this.model.ipregionlist);
        } else {
          this.$message.warning("请完善表格内容");
        }
      });
    },
    closeshowipregion() {
      this.model.ipregionlist = [];
      this.$emit("closesetipmodel");
    },
    showhistroymodel() {
      this.$emit("showhistroymodel");
    },
  },
};
</script>
<style lang="scss" scoped>
.setipmodel {
  ::v-deep .el-dialog {
    width: 1000px;
    height: 715px;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog__footer {
    padding: 0;
  }
  ::v-deep .el-dialog__body {
    padding: 12px 20px;
  }
  ::v-deep .el-form-item__content {
    line-height: 50px;
    top: 10px;
  }
  ::v-deep .el-form-item__error {
    padding-top: 0;
  }
  .table-bar {
    margin-bottom: 10px;
    .table-right {
      float: right;
      color: #409eff;
      cursor: pointer;
      margin-right: 10px;
      &:hover {
        border-bottom: 1px solid #409eff;
      }
    }
  }
  .warningtitle {
    height: 40px;
    margin-bottom: 20px;
    background-color: rgba(228, 229, 241);
    line-height: 40px;
    color: rgb(102, 102, 102);
    font-size: 14px;
    .warningcontent {
      margin-left: 20px;
    }
  }
}
</style>