<template>
  <el-pagination
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="currentPage"
    :page-sizes="[4, 6, 8, 12, 24, 30, 50]"
    :page-size="pagesize"
    layout="total,sizes, prev, pager, next, jumper"
    :total="total"
  >
  </el-pagination>
</template>
<script>
export default {
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    pagesize: {
      type: Number,
      default: 4,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  methods: {
    handleSizeChange(val) {
      this.$emit("handleSizeChange", val);
    },
    handleCurrentChange(current) {
      this.$emit("handleCurrentChange", current);
    },
  },
};
</script>
<style lang="scss" scoped>
</style>