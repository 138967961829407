<template>
  <div class="phonecontrol-wrap">
    <div class="bread-wrap">
      <span class="bread-title">云手机监控</span>
    </div>
    <div class="content">
      <div class="grouptree">
        <p class="grouptreetips">选中的云手机将展示在右侧操作界面</p>
        <p class="grouptreetips" style="color: red">
          （注：须使用Chrome浏览器）
        </p>
        <tree
          ref="treefather"
          :props="props"
          nodekey="Id"
          :defaultexpanded="defaultexpanded"
          :treedata="treedata"
          @treecheck="treecheck"
          @handleNodeExpand="handleNodeExpand"
          @handelNodeCollapse="handelNodeCollapse"
        />
      </div>
      <div class="phonecontent">
        <!-- <div class="phonetop">
          <top-control
            :isIndeterminate="isIndeterminate"
            :isallchecked="isallchecked"
            :checkeditem="checkeditem"
            :controldisabled="controldisabled"
            :PhoneInfos="PhoneInfos"
            @ifallchecked="ifallchecked"
            @showifconnect="showifconnect"
            @ifshowgpsmodel="ifshowgpsmodel"
            @uploadmodel="uploadmodel"
            @changetongbu="changetongbu"
            @getphonetask="getphonetask"
            @ifshowresolumodel="ifshowresolumodel"
            @fullscreen="fullscreen"
            @showsetipmodel="showsetipmodel"
          />
        </div> -->
        <div class="phoneview">
          <main-control
            ref="maincontrol"
            :pagesize="pagesize"
            :showphonelist="showphonelist"
            @showmodelcontrol="showmodelcontrol"
            @ifitemchecked="ifitemchecked"
          />
          <page-control
            :currentPage="currentPage"
            :pagesize="pagesize"
            :total="total"
            :checkednum="checkednum"
            @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"
          />
        </div>
      </div>
    </div>
    <control-model
      @closemodelcontrol="closemodelcontrol"
      :showmodel="showmodel"
      :data="modeldata"
      :iftongbu="iftongbu"
      :list="phonelist"
      :modeldata="modeldata"
      @singleuploadmodel="singleuploadmodel"
    />
    <if-connect
      :showconnect="showconnect"
      title="系统提示"
      :needcancel="false"
      confirmtext="确认"
      @closeifconnect="closeifconnect"
    />
    <set-resolution
      :showresolumodel="showresolumodel"
      title="批量设置分辨率&Dpi"
      :checkeditem="checkeditem"
      canceltext="取消"
      confirmtext="确认"
      @closeshowresolute="closeshowresolute"
      @getphonetask="getphonetask"
    />
    <set-gps
      :showgpsmodel="showgpsmodel"
      :checkeditem="checkeditem"
      title="批量设置GPS"
      canceltext="取消"
      confirmtext="确认"
      @closeshowgps="closeshowgps"
    />
    <!-- <up-load
      title="上传至云手机"
      :needfooter="false"
      :treedata="treedata"
      :showsingleuploadmodel="showsingleuploadmodel"
      @closeshowsingleupload="closeshowsingleupload"
    /> -->
    <single-up-load
      title="上传至云手机"
      :needfooter="false"
      :treedata="treedata"
      :phoneid="modeldata.Id"
      :phonename="modeldata.Name"
      :row="uploadrow"
      :showsingleuploadmodel="showsingleuploadmodel"
      @closeshowsingleupload="closeshowsingleupload"
    />
    <set-ip-model
      title="批量配置IP"
      :needfooter="false"
      :showsetip="showsetip"
      :checkeditem="noiplist"
      @closesetipmodel="closesetipmodel"
      @showhistroymodel="showhistroymodel"
    />
    <histroy-ip
      :showhistroyip="showhistroyip"
      :needfooter="false"
      title="历史配置"
      @closeshowhistroy="closeshowhistroy"
    />
  </div>
</template>
<script>
import TopControl from "./TopControl/index.vue";
import Tree from "@/components/Tree/index.vue";
import MainControl from "./MainControl/index.vue";
import PageControl from "./PageControl/index.vue";
import ControlModel from "./ControlModel/index.vue";
import IfConnect from "./Models/IfConnect.vue";
import SetGps from "./Models/SetGps.vue";
import SetResolution from "./Models/SetResolution.vue";
import UpLoad from "./Models/UpLoad.vue";
import SingleUpLoad from "./Models/SingleUpLoad.vue";
import SetIpModel from "./Models/SetIpModel.vue";
import { groupconnection } from "@/utils/groupconnection";
import HistroyIp from "./Models/HistroyIp.vue";
import { getCookie } from "../../utils/auth";

export default {
  name: "phonecontrol",
  data() {
    return {
      props: {
        label: function (data, node) {
          if (node.isLeaf) {
            let index = data.label.indexOf("-");
            let filtlable = data.label.substring(index + 1);
            let label = `${data.uphoneName}(${filtlable})`;
            return label;
          } else {
            return `${data.label}  (${data.children.length})`;
          }
        },
        children: "children",
      },
      controldisabled: false,
      treedata: [],
      phonelist: [],
      showphonelist: [],
      prephonelist: [],
      prevshowphonelist: [],
      checkedphonelist: [],
      currentPage: 1,
      pagesize: 8,
      total: 0,
      showmodel: false,
      modeldata: {},
      iftongbu: false,
      checkednum: 0,
      checkeditem: [],
      isIndeterminate: false,
      isallchecked: false,
      showconnect: false,
      showgpsmodel: false,
      showresolumodel: false,
      showuploadmodel: false,
      showsingleuploadmodel: false,
      timmer: null,
      uploadrow: [],
      rebootphonelist: [],
      norebootphonelist: [],
      showsetip: false,
      PhoneInfos: [],
      noiplist: [],
      showhistroyip: false,
      treedatavalue: [],
      defaultexpanded: [],
      checkedkeylist: [],
    };
  },
  components: {
    TopControl,
    Tree,
    MainControl,
    PageControl,
    ControlModel,
    IfConnect,
    SetGps,
    UpLoad,
    SingleUpLoad,
    SetResolution,
    SetIpModel,
    HistroyIp,
  },
  watch: {
    treedata: {
      handler: function (val) {
        // this.$refs.treefather.$refs.tree.setCheckedKeys(this.checkedkeylist);
      },
      deep: true,
    },
  },
  methods: {
    fullscreen() {
      this.$refs.maincontrol.fullplayer();
    },
    ifallchecked(val) {
      this.isIndeterminate = false;
      // this.checked = val;
      this.isallchecked = val;
      if (val == true) {
        this.checkednum = this.phonelist.length;
        this.checkeditem = [];
        this.phonelist.forEach((item) => {
          this.checkeditem.push(item.value);
        });
        this.checkedphonelist = this.phonelist;
      } else {
        this.checkednum = 0;
        this.checkeditem = [];
        this.checkedphonelist = [];
      }
      this.phonelist = this.phonelist.map((item) => {
        item.itemchecked = val;
        return item;
      });
      this.showphonelist = this.showphonelist.map((item) => {
        item.itemchecked = val;
        return item;
      });
    },
    ifitemchecked(val, value, checkitem) {
      //console.log(val, value);
      if (val == false) {
        --this.checkednum;
        this.checkeditem.forEach((item, index) => {
          if (item == value) {
            this.checkeditem.splice(index, 1);
          }
        });
        this.checkedphonelist.forEach((item, index) => {
          if (item == checkitem) {
            this.checkedphonelist.splice(index, 1);
          }
        });
      } else {
        ++this.checkednum;
        this.checkeditem.push(value);
        this.checkedphonelist.push(checkitem);
      }
      if (this.phonelist.length == this.checkednum) {
        this.isIndeterminate = false;
        // this.checked = true;
        this.isallchecked = true;
      }
      if (this.phonelist.length > this.checkednum && this.checkednum !== 0) {
        this.isIndeterminate = true;
        this.isallchecked = false;
      }
      if (this.phonelist.length > this.checkednum && this.checkednum == 0) {
        this.isIndeterminate = false;
        // this.checked = false;
        this.isallchecked = false;
      }
      this.phonelist = this.phonelist.map((item) => {
        if (item.value == value) {
          item.itemchecked = val;
        }
        return item;
      });
      // console.log(this.phonelist);
      this.showphonelist = this.showphonelist.map((item) => {
        if (item.value == value) {
          item.itemchecked = val;
        }
        return item;
      });
    },
    disabledcontrol(boolean) {
      this.treedata.forEach((item, index) => {
        let count = 0;
        item.children.forEach((eitem, eindex) => {
          if (this.rebootphonelist.length) {
            this.rebootphonelist.forEach((ritem) => {
              if (ritem == eitem.Id) {
                this.$set(eitem, "disabled", true);
                this.$set(eitem, "State", "REBOOTING");
                ++count;
              }
            });
            if (!this.rebootphonelist.includes(eitem.Id)) {
              this.$set(eitem, "disabled", false);
              this.$set(eitem, "State", "RUNNING");
            }
          } else {
            this.$set(eitem, "disabled", false);
            this.$set(eitem, "State", "RUNNING");
          }
        });
        if (count == item.children.length) {
          this.$set(item, "disabled", true);
        } else {
          this.$set(item, "disabled", false);
        }
      });
    },
    //设备状态轮训
    getphonetask(data) {
      // clearInterval(this.phonestatustimmer);
      this.getphonestatus();
      //重启的设备
      this.rebootphonelist = data.PhoneIds;
      console.log(data);
      // this.disabledcontrol();
      this.controldisabled = true;
      //console.log("getphonetask: " + JSON.stringify(data));
      //关闭重启的云手机连接
      let list = [];
      this.phonelist.forEach((item) => {
        if (item.itemchecked == false) {
          list.push(item);
        }
      });
      //未重启的设备
      this.norebootphonelist = list;

      // console.log(list)
      groupconnection(list, 0, []);
      // console.log(this.$store.getters.rtclist)
      this.timmer = setInterval(() => {
        this.$store.dispatch("group/getphonelist", data).then((res) => {
          if (res.RetCode == 0) {
            console.log(this.showvideolist);
            res.PhoneInfos.forEach((item) => {
              if (item.State == "RUNNING") {
                item["phoneId"] = item.Id;
                this.rebootphonelist.forEach((ritem, rindex) => {
                  if (ritem == item.Id) {
                    this.norebootphonelist.push(item);
                    console.log(this.norebootphonelist);
                    this.rebootphonelist.splice(rindex, 1);
                    console.log(this.rebootphonelist);
                  }
                });
                // this.disabledcontrol();
                groupconnection(this.norebootphonelist);
              }
              if (item.State == "REBOOT_FAILED") {
              }
            });

            if (this.rebootphonelist.length == 0) {
              // this.disabledcontrol();
              this.controldisabled = false;
              clearInterval(this.timmer);
            }
          }
        });
      }, 5000);
    },
    treecheck() {
      var checkedlist = this.$refs.treefather.$refs.tree.getCheckedNodes(
        false,
        true
      );
      this.checkedkeylist = this.$refs.treefather.$refs.tree.getCheckedKeys();
      // console.log(this.checkedkeylist);
      // console.log(checkedlist)
      this.prephonelist = [...this.phonelist];
      var oldphoneList = [...this.phonelist];
      var newphoneList = [];
      checkedlist.map((item, index) => {
        if (item.isLeaf == true) {
          newphoneList.push(item);
        }
      });
      this.phonelist = this.getfinnallist(oldphoneList, newphoneList);

      var newcheckphoneList = [];
      checkedlist.map((item, index) => {
        if (item.isLeaf == true && item.itemchecked == true) {
          newcheckphoneList.push(item);
        }
      });
      this.checkedphonelist = newcheckphoneList;
      this.checkeditem = this.checkedphonelist.map((item) => {
        return item.value;
      });

      this.total = this.phonelist.length;
      if (this.isallchecked == true) {
        this.checkednum = this.phonelist.length;
        this.checkeditem = this.phonelist.map((item) => {
          return item.value;
        });
        this.checkedphonelist = this.phonelist.map((item) => {
          return item;
        });
      }
      if (this.phonelist.length > this.pagesize) {
        this.filterlist(this.currentPage);
      } else {
        this.prevshowphonelist = this.showphonelist;
        this.showphonelist = this.phonelist;
      }
      this.showvideolist = [];
      for (let i = 0; i < this.phonelist.length; i++) {
        let phoneid = this.phonelist[i].phoneId;
        let pitem = null;
        if (this.existid(this.prephonelist, phoneid)) {
          //已经连接
          if (this.existid(this.prevshowphonelist, phoneid)) {
            if (this.existid(this.showphonelist, phoneid)) {
              pitem = { [phoneid]: "stos" };
            } else {
              pitem = { [phoneid]: "stoh" };
            }
          } else {
            if (this.existid(this.showphonelist, phoneid)) {
              pitem = { [phoneid]: "htos" };
            } else {
              pitem = { [phoneid]: "htoh" };
            }
          }
        } else {
          //未连接
          if (this.existid(this.showphonelist, phoneid)) {
            pitem = { [phoneid]: "ntos" };
          } else {
            pitem = { [phoneid]: "ntoh" }; //从无到显示
          }
        }
        this.showvideolist.push(pitem);
      }
      console.log("当前选中");
      console.log(this.phonelist);
      console.log("当前重启");
      console.log(this.rebootphonelist);
      if (this.rebootphonelist.length) {
        let list = JSON.parse(JSON.stringify(this.phonelist));
        console.log(list);
        this.phonelist.forEach((item, index) => {
          this.rebootphonelist.forEach((ritem, rindex) => {
            if (ritem == item.Id) {
              list.forEach((litem, lindex) => {
                if (ritem == litem.Id) {
                  list.splice(lindex, 1);
                }
              });
            }
          });
        });
        this.norebootphonelist = list;
        console.log(this.norebootphonelist);
        groupconnection(this.norebootphonelist, 0, this.showvideolist);
      } else {
        groupconnection(this.phonelist, 0, this.showvideolist);
      }
    },
    getfinnallist(oldlist, newlist) {
      let relist = [];
      let inlist = [];
      let finnallist = [];
      for (let i = 0; i < oldlist.length; i++) {
        let flag = false;
        for (let j = 0; j < newlist.length; j++) {
          if (oldlist[i].phoneId == newlist[j].phoneId) {
            flag = true;
            break;
          }
        }
        if (!flag) {
          relist.push(oldlist[i]);
        }
      }
      for (let i = 0; i < newlist.length; i++) {
        let flag = false;
        for (let j = 0; j < oldlist.length; j++) {
          if (newlist[i].phoneId == oldlist[j].phoneId) {
            flag = true;
            break;
          }
        }
        if (!flag) {
          if (this.isallchecked == true) {
            newlist[i]["itemchecked"] = true;
          } else {
            newlist[i]["itemchecked"] = false;
          }
          inlist.push(newlist[i]);
        }
      }
      if (inlist.length) {
        finnallist = [...oldlist, ...inlist];
      }
      if (relist.length) {
        relist.forEach((ritem, rindex) => {
          oldlist.forEach((oitem, oindex) => {
            if (ritem.phoneId == oitem.phoneId) {
              oldlist.splice(oindex, 1);
            }
          });
        });
        finnallist = oldlist;
      }
      return finnallist;
    },
    filterlist(i) {
      if (
        this.showphonelist.length == 1 &&
        this.phonelist.length < this.prephonelist.length
      ) {
        let startindex = (i - 2) * this.pagesize;
        let endindex = (i - 1) * this.pagesize;
        this.prevshowphonelist = this.showphonelist;
        this.showphonelist = this.phonelist.slice(startindex, endindex);
        this.currentPage = i - 1;
        return;
      }
      let startindex = (i - 1) * this.pagesize;
      let endindex = i * this.pagesize;
      this.prevshowphonelist = this.showphonelist;
      this.showphonelist = this.phonelist.slice(startindex, endindex);
    },
    handleSizeChange(val) {
      this.pagesize = val;
      this.filterlist(1);
      this.currentPage = 1;
      this.checkShowVideo();
    },
    handleCurrentChange(current) {
      this.filterlist(current);
      this.currentPage = current;
      this.checkShowVideo();
    },
    checkShowVideo() {
      this.showvideolist = [];
      for (let i = 0; i < this.phonelist.length; i++) {
        let pitem = null;
        let phoneid = this.phonelist[i].phoneId;
        if (this.existid(this.showphonelist, phoneid)) {
          if (this.existid(this.prevshowphonelist, phoneid)) {
            pitem = { [phoneid]: "stos" }; //从显示到显示
          } else {
            pitem = { [phoneid]: "htos" }; //从隐藏到显示
          }
        } else {
          if (this.existid(this.prevshowphonelist, phoneid)) {
            pitem = { [phoneid]: "stoh" }; //从显示到隐藏
          } else {
            pitem = { [phoneid]: "htoh" }; //从隐藏到隐藏
          }
        }
        this.showvideolist.push(pitem);
      }
      if (this.rebootphonelist.length) {
        groupconnection(this.norebootphonelist, 0, this.showvideolist);
      } else {
        groupconnection(this.phonelist, 0, this.showvideolist);
      }
    },
    resetShowVideoList() {
      //console.log("before: " + JSON.stringify(this.showvideolist));
      var checkedUphones = this.xphoneToUphone(this.checkeditem);
      var svList = [];
      for (let i = 0; i < this.showvideolist.length; i++) {
        var key = Object.keys(this.showvideolist[i]);
        var showflag = Object.values(this.showvideolist[i]);
        for (let j = 0; j < checkedUphones.length; j++) {
          if (checkedUphones[j] == key) {
            if (showflag == "stos" || showflag == "htos") {
              showflag = "ntos";
            }
            if (showflag == "stoh" || showflag == "htoh") {
              showflag = "ntoh";
            }
          }
        }
        let pitem = { [key]: showflag };
        svList.push(pitem);
      }
      this.showvideolist = svList;
      //console.log("after: " + JSON.stringify(this.showvideolist));
    },
    xphoneToUphone(xphones) {
      var uphones = [];
      for (let i = 0; i < xphones.length; i++) {
        for (let j = 0; j < this.phonelist.length; j++) {
          if (xphones[i] == this.phonelist[j].value) {
            uphones.push(this.phonelist[j].phoneId);
            break;
          }
        }
      }
      return uphones;
    },
    existid(list, id) {
      let find = false;
      for (let j = 0; j < list.length; j++) {
        if (id == list[j].phoneId) {
          find = true;
          break;
        }
      }
      return find;
    },
    showmodelcontrol(treeitem, index) {
      this.modeldata = treeitem;
      if (this.rebootphonelist.includes(treeitem.phoneId)) {
        this.$message.warning("这台设备正在重启");
        return;
      }
      let list = JSON.parse(JSON.stringify(this.phonelist));
      this.phonelist.forEach((item, index) => {
        if (item.phoneId == treeitem.phoneId) {
          list.splice(index, 1);
        }
      });
      if (this.rebootphonelist.length) {
        this.norebootphonelist.forEach((item, index) => {
          if (item.Id == treeitem.phoneId) {
            this.norebootphonelist.splice(index, 1);
          }
        });
        groupconnection(this.norebootphonelist);
      } else {
        groupconnection(list);
      }
      let refsvideo = this.$refs.maincontrol.$refs.videoitem;
      let videoitem = this.getvideoitem(refsvideo, treeitem.phoneId);
      videoitem.clearstatus();
      this.showmodel = !this.showmodel;
    },
    closemodelcontrol(modelitem) {
      this.showmodel = !this.showmodel;
      if (this.rebootphonelist.length) {
        this.norebootphonelist.push(modelitem);
        groupconnection(this.norebootphonelist, 500);
      } else {
        groupconnection(this.phonelist, 500);
      }
    },
    getvideoitem(videogroup, phoneId) {
      let selecteditem;
      videogroup.map((item) => {
        if (item.id == phoneId) {
          selecteditem = item;
        }
        return;
      });
      return selecteditem;
    },
    changetongbu(val) {
      this.iftongbu = val;
    },
    showifconnect() {
      this.showconnect = !this.showconnect;
    },
    closeifconnect() {
      this.showconnect = !this.showconnect;
    },
    ifshowresolumodel() {
      this.showresolumodel = !this.showresolumodel;
    },
    closeshowresolute() {
      this.showresolumodel = !this.showresolumodel;
    },
    ifshowgpsmodel() {
      this.showgpsmodel = !this.showgpsmodel;
    },
    showsetipmodel(noiplist) {
      this.showsetip = !this.showsetip;
      this.noiplist = noiplist;
    },
    closesetipmodel() {
      this.showsetip = !this.showsetip;
    },
    showhistroymodel() {
      this.showhistroyip = !this.showhistroyip;
    },
    closeshowhistroy() {
      this.showhistroyip = !this.showhistroyip;
    },
    uploadmodel() {
      this.selection = [];
      for (let item of this.checkedphonelist) {
        let renewform = {
          product_type: "Phone",
          region: item.IpRegion,
          config_type: item.ProductId,
          name: item.Id,
          group: item.GroupName,
          status: item.State,
        };
        this.selection.push(renewform);
      }
      this.uploadrow = this.selection;
      this.showsingleuploadmodel = !this.showsingleuploadmodel;
    },
    singleuploadmodel() {
      this.selection = [];
      for (let item of [this.modeldata]) {
        let renewform = {
          product_type: "Phone",
          region: item.IpRegion,
          config_type: item.ProductId,
          name: item.Id,
          group: item.GroupName,
          status: item.State,
        };
        this.selection.push(renewform);
      }
      this.uploadrow = this.selection;
      this.showsingleuploadmodel = !this.showsingleuploadmodel;
    },

    closeshowgps() {
      this.showgpsmodel = !this.showgpsmodel;
    },
    closeshowupload() {
      this.showsingleuploadmodel = !this.showsingleuploadmodel;
    },
    closeshowsingleupload() {
      this.showsingleuploadmodel = !this.showsingleuploadmodel;
    },
    getgrouplist() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch("sub/getsublist").then((res) => {
          if (res.RetCode == 0) {
            resolve(res.UserInfos);
          }
        });
      });
    },
    getphonelist() {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("sub/getphonelist", { PhoneRegion: 1 })
          .then((res) => {
            if (res.RetCode == 0) {
              this.PhoneInfos = res.PhoneInfos;
              this.PhoneInfos.sort(function (a, b) {
                return a.Name > b.Name ? 1 : -1;
              });
              resolve(this.PhoneInfos);
            }
          });
      });
    },
    gettreedata() {
      Promise.all([this.getgrouplist(), this.getphonelist()]).then(
        ([UserInfos, PhoneInfos]) => {
          let newList = {
            UserId: getCookie("userId"),
            Username: getCookie("username"),
          };
          UserInfos.unshift(newList);

          const filterlist =
            UserInfos.length &&
            UserInfos.filter((item, index) => {
              item["children"] = [];
              PhoneInfos.map((eitem, eindex) => {
                eitem["label"] = eitem.Id;
                eitem["value"] = eitem.Id;
                eitem["phoneId"] = eitem.OutId;
                eitem["uphoneName"] = eitem.Name;
                eitem["createTime"] = eitem.CreateTime;
                eitem["expireTime"] = eitem.ExpireTime;
                eitem["state"] = eitem.State;
                eitem["isLeaf"] = true;
                if (eitem.UserId == item.UserId) {
                  item["Name"] = item.Username;
                  item["Id"] = item.UserId;
                  item["label"] = item.Username;
                  item["isLeaf"] = false;
                  item["children"].push(eitem);
                }
              });
              if (item.children.length !== 0) {
                return true;
              } else {
                return false;
              }
            });

          this.treedatavalue = filterlist;
          this.getphonestatus();
          this.treedata = this.treedatavalue;
        }
      );
    },
    handleNodeExpand(data, node) {
      let flag = false;
      this.defaultexpanded.some((item) => {
        if (item == data["Id"]) {
          flag = true;
          return true;
        }
      });
      if (!flag) {
        this.defaultexpanded.push(data.Id);
      }
      //  console.log(this.defaultexpanded)
    },
    handelNodeCollapse(data, node) {
      this.defaultexpanded.some((item, index) => {
        if (item == data["Id"]) {
          this.defaultexpanded.splice(index, 1);
        }
      });
      // console.log(this.defaultexpanded)
    },
    discontrolphone(list) {
      this.treedatavalue.forEach((item, index) => {
        let count = 0;
        item.children.forEach((eitem, eindex) => {
          list.forEach((litem, lindex) => {
            if (
              litem.Id == eitem.Id &&
              (litem.State == "RESETTING" ||
                litem.State == "REBOOTING" ||
                litem.State == "CREATING" ||
                litem.State == "SETTING_ROOT" ||
                litem.State == "RENEWING" ||
                litem.State == "CREATE_FAILED")
            ) {
              ++count;
              this.$set(eitem, "disabled", true);
              this.$set(eitem, "State", litem.State);
            }
            if (
              litem.Id == eitem.Id &&
              litem.State !== "RESETTING" &&
              litem.State !== "REBOOTING" &&
              litem.State !== "CREATING" &&
              litem.State !== "SETTING_ROOT" &&
              litem.State !== "RENEWING" &&
              litem.State !== "CREATE_FAILED"
            ) {
              this.$set(eitem, "disabled", false);
              this.$set(eitem, "State", litem.State);
            }
          });
        });
        if (count == item.children.length) {
          this.$set(item, "disabled", true);
        } else {
          this.$set(item, "disabled", false);
        }
      });
    },
    getphonestatus() {
      var list = [];
      this.$store.dispatch("group/getphonelist").then((res) => {
        if (res.RetCode == 0) {
          list = [...res.PhoneInfos];
        }
        this.discontrolphone(list);
        // console.log(list);
      });
    },
  },
  mounted() {},
  activated() {
    //获取分组树
    this.gettreedata();
    //清空状态刷新定时器
    if (this.phonestatustimmer !== null) {
      clearInterval(this.phonestatustimmer);
    }
    //刷新分组树状态
    this.phonestatustimmer = setInterval(() => {
      this.getphonestatus();
    }, 5000);
    // console.log("activated");
  },
  deactivated() {
    // console.log("deactivated");
    this.treedata = [];
    this.phonelist = [];
    this.showphonelist = [];
    this.prephonelist = [];
    this.prevshowphonelist = [];
    this.modeldata = {};
    this.checkeditem = [];
    groupconnection(this.phonelist, 0, []);
    this.$notify.close();
    this.checkedphonelist = [];
    clearInterval(this.timmer);
    this.disabledcontrol(false);
    this.controldisabled = false;
    this.rebootphonelist = [];
    this.norebootphonelist = [];
    clearInterval(this.phonestatustimmer);
    this.defaultexpanded = [];
    this.checkedkeylist = [];
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>