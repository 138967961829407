var render = function render(){var _vm=this,_c=_vm._self._c;return _c('models',{staticClass:"setipmodel",attrs:{"showmodel":_vm.showsetip,"title":_vm.title,"canceltext":_vm.canceltext,"confirmtext":_vm.confirmtext,"needcancel":_vm.needcancel},on:{"confirm":_vm.confirmipregion,"closemodel":_vm.closeshowipregion,"open":_vm.open}},[_c('div',{staticClass:"warningtitle"},[_c('p',{staticClass:"warningcontent"},[_vm._v("只针对无网版云手机配置海外IP")])]),_c('div',{staticClass:"table-bar"},[_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":_vm.handleDownload}},[_vm._v("导出Excel模板")]),_c('upload-excel',{attrs:{"on-success":_vm.handleSuccess,"before-upload":_vm.beforeUpload}}),_c('span',{staticClass:"table-right",on:{"click":_vm.showhistroymodel}},[_vm._v("历史配置")])],1),_c('el-form',{ref:"socksform",attrs:{"model":_vm.model}},[_c('com-table',{ref:"comtable",staticStyle:{"width":"100%"},attrs:{"headercellstyle":_vm.headercellstyle,"tablesize":"small","tableheight":"400","pagination":_vm.pagination,"columns":_vm.ipcolumns,"data":_vm.model.ipregionlist},on:{"rowClassName":_vm.rowClassName,"handleSelectionChange":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"slot":"action","align":"left","label":"IP地址/域名","width":"200","prop":"IP"},slot:"action",scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":`ipregionlist[${scope.$index}].IP`,"rules":[
              { required: true, message: 'IP地址/域名不能为空', trigger: 'blur' },
              {
                pattern: /^[^\u4e00-\u9fa5]+$/,
                message: '不能存在中文',
                trigger: 'blur',
              },
            ]}},[_c('el-input',{attrs:{"clearable":true,"size":"mini","placeholder":"请输入IP或域名"},model:{value:(scope.row.IP),callback:function ($$v) {_vm.$set(scope.row, "IP", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"scope.row.IP"}})],1)]}}])}),_c('el-table-column',{attrs:{"slot":"action","align":"left","label":"IP端口","width":"150","prop":"dk"},slot:"action",scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":`ipregionlist[${scope.$index}].dk`,"rules":[
              { required: true, message: 'IP端口不能为空', trigger: 'blur' },
              {
                pattern: /^[^\u4e00-\u9fa5]+$/,
                message: '不能存在中文',
                trigger: 'blur',
              },
            ]}},[_c('el-input',{attrs:{"clearable":true,"size":"mini","placeholder":"请输入IP端口"},model:{value:(scope.row.dk),callback:function ($$v) {_vm.$set(scope.row, "dk", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"scope.row.dk"}})],1)]}}])}),_c('el-table-column',{attrs:{"slot":"action","align":"left","label":"IP账号","width":"200","prop":"account"},slot:"action",scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":`ipregionlist[${scope.$index}].account`,"rules":[
              { required: true, message: '请输入IP账号', trigger: 'blur' },
            ]}},[_c('el-input',{attrs:{"clearable":true,"size":"mini","placeholder":"请输入IP账号"},model:{value:(scope.row.account),callback:function ($$v) {_vm.$set(scope.row, "account", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"scope.row.account"}})],1)]}}])}),_c('el-table-column',{attrs:{"slot":"action","align":"left","label":"IP密码","width":"200","prop":"password"},slot:"action",scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":`ipregionlist[${scope.$index}].password`,"rules":[
              { required: true, message: '请输入IP密码', trigger: 'blur' },
            ]}},[_c('el-input',{attrs:{"clearable":true,"type":"password","show-password":true,"size":"mini","placeholder":"请输入IP密码"},model:{value:(scope.row.password),callback:function ($$v) {_vm.$set(scope.row, "password", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"scope.row.password"}})],1)]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }