<template>
  <models
    :showmodel="showconnect"
    :title="title"
    :canceltext="canceltext"
    :confirmtext="confirmtext"
    :needcancel="needcancel"
    @confirm="closeifconnect"
    @closemodel="closeifconnect"
    class="ifconnect"
  >
    <div>
      <p>
        开启同步操作，则操作任意一台云手机，会将操作同步至本组中已勾选的云手机！
      </p>
    </div>
  </models>
</template>
<script>
import Models from "../../../components/Models/index.vue";
export default {
  props: {
    showconnect: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    canceltext: {
      type: String,
    },
    confirmtext: {
      type: String,
    },
    needcancel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  components: {
    Models,
  },
  methods: {
    closeifconnect() {
      this.$emit("closeifconnect");
    },
  },
};
</script>
<style lang="scss" scoped>
.ifconnect {
  ::v-deep .el-dialog {
    width: 374px;
    height: 214px;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog__footer {
    padding: 0;
  }
}
</style>